import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Card, CardBody, Row, Col, Table, FormGroup, Button } from 'reactstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import PaymentStatus from '../Order/PaymentStatus';
import {getProducts, getOwners} from '../Common/DataFunctions.js';

var money = require("money-math");


class EcommerceOrderView extends Component {
    constructor(props){
        super(props);
        this.state = {
            order_id: null,
            credit_order_id: 0,
            cart: {},
            order: {},
            owners: {},
            products: {},
            total: "0",
            subtotal: "0",
            tax: "0",
            deposit: "0",
            credit_applied: "0",
            downloading: false,
            loading: true,
        }
    }
    componentDidMount(){
        document.title = "Order # " + this.props.match.params.order_id + " | Bevvy";
        this.setState({order_id: this.props.match.params.order_id});
        var self = this;
        axios.defaults.withCredentials = true;
        getOwners(function (owners) {
            getProducts(function (products) {
                axios.get(API_ROOT + '/order/' + self.props.match.params.order_id)
                    .then(function (response) {
                        if (response.data.account_id != self.props.active_account) {
                            Swal("Error", "That order does not belong to this account", "error");
                        } else {
                            if (response.data.payment_method == 0) {
                            self.setState({payment_method_print: "Account Credit"});
                        } else if (response.data.payment_method == 1) {
                                self.setState({payment_method_print: response.data.card_type.charAt(0).toUpperCase() + response.data.card_type.slice(1) + ' ****' + response.data.last_four});
                            } else if (response.data.payment_method == 3) {
                                self.setState({payment_method_print: 'EFT'});
                            } else {
                                self.setState({payment_method_print: 'Cheque'});
                            }
                            if (localStorage.getItem('show_success' + response.data.order_id) == "true") {
                                localStorage.setItem('show_success' + response.data.order_id, false);
                                Swal("Order Successfully Placed", "<p>" + ('1' == '1' ? "A receipt" : "An invoice") + " has been emailed to you, and your order is now being prepared for delivery. Thank you!</p>", "success");
                            }
                            if (localStorage.getItem('show_partial' + response.data.order_id) == "true") {
                                localStorage.setItem('show_partial' + response.data.order_id, false);
                                Swal("Order Partially Placed", "<p>Some of your selected items could not be ordered. Please see the below error message for details and try again. " + ('1' == '1' ? "A receipt" : "An invoice") + " has been emailed to you for the sucesfully ordered product(s).</p>", "warning");
                            }
                            let zero = money.floatToAmount(0);
                            var quantity = 0;
                            var total_total = zero;
                            var total_subtotal = zero;
                            var total_tax = zero;
                            var total_deposit = zero;
                            var cart = {};
                            response.data.items.forEach(function (item) {
                                let product = products[item.product_id];
                                let cart_owner = cart[product.product_owner_id];
                            if (typeof cart_owner == "undefined") {
                                cart[product.product_owner_id] = {
                                    "items": [],
                                    "subtotal": zero,
                                    "tax": zero,
                                    "deposit": zero,
                                    "total": zero,
                                    "quantity": 0
                                };
                                cart_owner = cart[product.product_owner_id]; //set because was undefined
                            }
                            let subtotal = money.floatToAmount(item.subtotal);
                            let deposit = money.floatToAmount(item.deposit);
                            let tax = money.floatToAmount(item.tax);
                            let total = money.add(tax, money.add(deposit, subtotal));
                            cart[product.product_owner_id].items.push({
                                product_id: item.product_id,
                                quantity: money.floatToAmount(item.quantity),
                                subtotal: subtotal,
                                tax: tax,
                                deposit: deposit,
                                total: total
                            });

                            cart[product.product_owner_id].quantity = (item.quantity + cart_owner.quantity);
                            cart[product.product_owner_id].subtotal = money.add(subtotal, cart_owner.subtotal);
                            cart[product.product_owner_id].tax = money.add(tax, cart_owner.tax);
                            cart[product.product_owner_id].deposit = money.add(deposit, cart_owner.deposit);
                            cart[product.product_owner_id].total = money.add(total, cart_owner.total);

                            total_subtotal = money.add(subtotal, total_subtotal);
                            total_tax = money.add(tax, total_tax);
                            total_deposit = money.add(deposit, total_deposit);
                            total_total = money.add(total, total_total);
                        });
                        self.setState({
                            total: total_total,
                            credit_applied: response.data.credit_applied,
                            tax: total_tax,
                            subtotal: total_subtotal,
                            deposit: total_deposit,
                            owners: owners,
                            order: response.data,
                            products: products,
                            cart: cart,
                            credit_order_id: response.data.credit_order_id,
                            loading: false
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
            });
        });
    }
    getPDF = function() {
        this.setState({downloading:true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT+'/order/'+this.state.order_id+"/invoice")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Bevvy Order #' + self.state.order_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading:false});
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading:false});
            });
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    {(this.state.credit_order_id > 0 ? "Credit Memo" : "Order")} #{this.state.order_id}
                    <div className="ml-auto">
                        <Button color="info" onClick={this.getPDF.bind(this)} disabled={this.state.downloading}>
                            <i className={(this.state.downloading ? "fa fa-spinner fa-spin" : "fa fa-download")}></i> Printable Receipt</Button>
                    </div>
                </div>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="4" sm="5">Order Number:</Col>
                                        <Col md="8" sm="7">
                                        <strong>{this.state.order.order_id}</strong>
                                        </Col>
                                    </FormGroup><FormGroup row>
                                        <Col md="4" sm="5">Date/Time Ordered:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.order.date_ordered}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Placed By:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.order.first_name} {this.state.order.last_name}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Delivery Instructions:</Col>
                                        <Col md="8" sm="7" className="font-weight-bold font-italic">
                                            {this.state.order.delivery_instructions}
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Col>
                            <Col lg="6">
                                <p className="lead bb">Billing</p>
                                <form className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="4" sm="5">Subtotal:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.subtotal}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">+Tax:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.tax}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">+Deposit:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.deposit}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.credit_applied == 0 ? "d-none" : "")}>
                                        <Col md="4" sm="5">-Account Credit:</Col>
                                        <Col md="8" sm="7">
                                            <strong className="text-success"><u>(${parseFloat(this.state.credit_applied).toFixed(2)})</u></strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">=Total Payable:</Col>
                                        <Col md="8" sm="7">
                                            <strong><u>${parseFloat(parseFloat(this.state.total) - parseFloat(this.state.credit_applied)).toFixed(2)}</u></strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Payment Method</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.payment_method_print}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Status</Col>
                                        <Col md="8" sm="7">
                                            <PaymentStatus is_paid={this.state.order.is_paid} company_id={this.state.order.company_id}/>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className={(this.state.loading?"card card-default whirl traditional":"card card-default")}>
                    <CardBody>
                     <p className="lead bb">Order Items</p>
                    {Object.keys(this.state.cart).map(function(product_owner_id) {
                        function pad(n, width, z) {
                          z = z || '0';
                          n = n + '';
                          return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
                        }
                        return (
                            <div className="mb-2 pb-2 pt-1" style={{"borderBottom":"2px solid #ccc"}}>
                                <div className="h4 mb-2">
                                    {this.state.owners[product_owner_id].name}
                                </div>
                                <Table size="sm" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Product</th>
                                            <th>Cost/Each</th>
                                            <th>Quantity</th>
                                            <th>Subtotal</th>
                                            <th className="d-none d-sm-table-cell">+Deposit</th>
                                            <th className="d-none d-sm-table-cell">+Tax</th>
                                            <th className="d-none d-sm-table-cell">=Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.state.cart[product_owner_id].items).map(function(id, key) {
                                            var item = this.state.cart[product_owner_id].items[id];
                                            return (
                                            <tr>
                                                <td>
                                                    <a href={'../img/products/'+item.product_id+'.jpg'} className="float-left mr-3 d-none d-sm-block" data-lity>
                                                        <img src={'../img/products/'+item.product_id+'_sm.png'} style={{height:'40px'}}></img>
                                                    </a>
                                                {this.state.products[item.product_id].name}
                                                </td>
                                                <td>${money.div(item.subtotal,item.quantity)}</td>
                                                <td>{Math.round(item.quantity)}</td>
                                                <td>${item.subtotal}</td>
                                                <td className="d-none d-sm-table-cell">${item.deposit}</td>
                                                <td className="d-none d-sm-table-cell">${item.tax}</td>
                                                <td className="d-none d-sm-table-cell">${item.total}</td>
                                            </tr>
                                            )
                                        },this)}
                                    </tbody>
                                     <tfoot>
                                        <tr>
                                            <th></th>
                                            <th>Total:</th>
                                            <th>{this.state.cart[product_owner_id].quantity}</th>
                                            <th>${this.state.cart[product_owner_id].subtotal}</th>
                                            <th className="d-none d-sm-table-cell">${this.state.cart[product_owner_id].deposit}</th>
                                            <th className="d-none d-sm-table-cell">${this.state.cart[product_owner_id].tax}</th>
                                            <th className="d-none d-sm-table-cell"><u>${this.state.cart[product_owner_id].total}</u></th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                            )
                        },this)}
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default EcommerceOrderView;